import React, { Component } from 'react';
import './App.css';
import { TextField, Button, CssBaseline, Container, CircularProgress } from '@material-ui/core';
import Logo from './images/FM_Capital.svg'
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios'
import { lighten, makeStyles, withStyles } from '@material-ui/core/styles';

const recaptchaRef = React.createRef();

export class App extends Component {
  state = {
    name: '',
    phone: '',
    email: '',
    position: '',
    startDate: '',
    referrer: ''
  }

  onSubmit = (e) => {
    e.preventDefault();
    const recaptchaValue = recaptchaRef.current.getValue();
    if (!recaptchaValue) {
      alert('Complete captcha');
      return;
    }
    console.log(JSON.stringify(this.state));

    this.setState({ sending: true });

    axios.post(
      "https://sendemailfm.azurewebsites.net/api/SendEmail?code=a0vljez40/AGTBF1s41pqmtFuxnAcaStb5gk19posU5itHXf6LAowg==",
      {
        sender: "noreply@fmm.com",
        receiver: "afhima@fmm.com",
        cc: "jsinensky@fmm.com",
        bcc: "yornstein@fmm.com",
        subject: "New FM Capital Employee",
        body: JSON.stringify(this.state)
      }
    ).then(res => {
      this.setState({ sent: true });
    }).catch(err => {
      console.log(err)
      this.setState({ error: true });
    });
  }

  onChange = (e) => {
    console.log(e.target.value)
    this.setState({ [e.target.id]: e.target.value });
  }

  render() {

    return (
      <React.Fragment>
        <CssBaseline />
        <Container maxWidth='md' style={{ textAlign: 'center' }}>
          <a href="https://fmcapital.com/" target="_blank">
            <img src={Logo} alt="icon" style={{ marginTop: "15px" }} />
          </a>
          {this.state.error && <div style={{ maxWidth: '450px', backgroundColor: 'red', marginLeft: '25%' }}><h2>An error occurred</h2></div>}
          {this.state.sent ? <div style={{ maxWidth: '450px', backgroundColor: 'lightGreen', marginLeft: '25%' }}><h2>New Employee Submitted Successfully</h2></div> : <form onSubmit={this.onSubmit} >
            <h2>Please complete the following information:</h2>
            <div style={{ maxWidth: '350px', marginLeft: '30%' }}>
              <TextField required id="name" label="Name" variant="outlined" fullWidth={true} style={{ marginBottom: '5px' }} value={this.state.name} onChange={this.onChange} />
              <TextField required type="email" id="email" label="Email" variant="outlined" fullWidth={true} style={{ marginBottom: '5px' }} value={this.state.email} onChange={this.onChange} />
              <TextField required id="phone" label="Phone" variant="outlined" fullWidth={true} style={{ marginBottom: '5px' }} value={this.state.phone} onChange={this.onChange} />
              <TextField required id="position" label="Position" variant="outlined" fullWidth={true} style={{ marginBottom: '5px' }} value={this.state.position} onChange={this.onChange} />
              <TextField required
                id="startDate"
                label="Start Date"
                type="date"
                variant="outlined"
                fullWidth={true}
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ marginBottom: '5px' }}
                value={this.state.startDate}
                onChange={this.onChange}

              />
              <TextField required id="referrer" label="Referred by" variant="outlined" fullWidth={true} style={{ marginBottom: '10px' }} value={this.state.referrer} onChange={this.onChange} />
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey="6Lfb8cUUAAAAAAMpify2yEgoX6LliMZC3XiNp9UD"
                onChange={this.onChange}
                style={{ marginBottom: '10px', marginLeft: '25px' }}
              />
            </div>
            <Button disabled={this.state.sending} style={{marginBottom: "10px"}} type="submit" color="primary" variant="contained" text="Submit">{this.state.sending ? 'Submitting' : 'Submit'} {this.state.sending && <FacebookProgress style={{ marginTop: '5px', marginLeft: '5px' }} />}</Button>
          </form>}
        </Container>
      </React.Fragment>
    );
  }
}

export default App;

const useStylesFacebook = makeStyles({
  root: {
    position: 'relative',
  },
  top: {
    color: '#eef3fd',
  },
  bottom: {
    color: '#6798e5',
    animationDuration: '550ms',
    position: 'absolute',
    left: 0,
  },
});

function FacebookProgress(props) {
  const classes = useStylesFacebook();

  return (
    <div className={classes.root}>
      <CircularProgress
        variant="determinate"
        value={100}
        className={classes.top}
        size={24}
        thickness={4}
        {...props}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        className={classes.bottom}
        size={24}
        thickness={4}
        {...props}
      />
    </div>
  );
}